<template>
  <div>
    <Datepicker
      range
      autoApply
      multiCalendars
      :transitions="true"
      v-model="date"
      @closed="updateDateRange"
      :presetRanges="presetRanges"
      :closeOnAutoApply="true">
    </Datepicker>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { endOfMonth, endOfYear, startOfMonth, startOfYear, startOfWeek, endOfWeek } from 'date-fns';

export default {
  name: 'DateRangePicker',
  props: ['application'],
  components: { 
    Datepicker 
  },
  data() {
    return {
      date: [moment(), moment()],
      presetRanges: [
          { label: 'Now', range: [new Date(), new Date()] },
          { label: 'Last 5 minutes', range: [moment().subtract(5, 'minute'), moment().subtract()]},
          { label: 'Last 30 minutes', range: [moment().subtract(30, 'minute'), moment().subtract()]},
          { label: 'Last hour', range: [moment().subtract(1, 'hour'), moment().subtract()]},
          { label: 'Last 24 hours', range: [moment().subtract(1, 'day'), moment().subtract()]},
          { label: 'This week', range: [startOfWeek(new Date()), endOfWeek(new Date())] },
          { label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
          { label: 'This year', range: [startOfYear(new Date()), endOfYear(new Date())] },
        ]
    };
  },
  computed: {
    pickerTheme(){
      let isDark = null
      
      if(this.application.theme === 'dark'){
        isDark = true
      }
      else if(this.application.theme === 'light'){
        isDark = false
      }
      else if(this.application.theme === 'auto'){
        const hours = moment().format('HH')

        if(hours >= 19){
          isDark = true
        }
        else {
          isDark = false
        }
      }
      return isDark
    }
  },
  methods: {
    updateDateRange(){
      this.$emit('updateDateRange', this.date)
    }
  }
};
</script>

<style lang="scss" scoped>
  div[class=dp__preset_ranges]{
    background-color: #833e3e;
  }

</style>
