<template>
  <div>
    <select
    class="form-select" 
    aria-label="Select a conversion unit"
    @input="updateConversionUnits">
      <option selected>Select a conversion unit</option>
      <option value="kelvin">Kelvin</option>
      <option value="celsius">Celsius</option>
      <option value="fahrenheit">Fahrenheit</option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'ConversionUnitSelector',
  data(){
    return{}
  },
  methods: {
    updateConversionUnits(event){
      this.$emit('updateConversionUnits', event.target.value)
    }
  }
}
</script>