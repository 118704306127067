<template>
  <div>
    <div class="modal fade" id="chartsFiltersModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" :style="settings.fg">
          <div class="modal-header">
            <h5 class="modal-title text-capitalize" id="exampleModalLabel">{{ displayName || 'Chart settings' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group" :style="settings.fg">
                 <div class="col-md-12 mt-2">
                   <label for="limit mt-2">Bar Limit</label>
                   <input v-model="form.limit" class="form-control mt-2" type="text" name="" id="">
                 </div>

                 <div class="col-md-12 mt-2">
                   <label for="date-range mt-2">Date Range</label>
                   <DateRangePicker
                    class="mt-2"
                    :application="application"
                    @updateDateRange="updateDateRange"/>
                 </div>

                 <div class="col-md-12 mt-2">
                   <label for="date-range mt-2">Color Palette</label>
                   <ColorPaletteSector
                    class="mt-2"
                    @updateDefaultPalette="updateDefaultPalette"/>
                 </div>

                 <div class="col-md-12 mt-2">
                   <label for="date-range mt-2">Conversion Unit</label>
                   <ConversionUnitSelector
                    :settings="settings"
                    class="mt-2"
                    @updateConversionUnits="updateConversionUnits"/>
                 </div>

               </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-bs-dismiss="modal" class="btn btn-primary" @click.prevent="saveSettings">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import DateRangePicker from '../date-range/DateRangePicker.vue'
import ColorPaletteSector from '../settings/system-settings/sections/chart-section/ColorPaletteSector.vue'
import ConversionUnitSelector from '../charts/chart-options/ConversionUnitSelector.vue'

const ReportsModule = createNamespacedHelpers('report')
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: 'ChartsFiltersModal',
  data(){
    return {
      form: {
        limit: 10,
        palette: null,
        dateRange: null,
        conversionUnit: null,
      }
    }
  },
  components: {
    DateRangePicker,
    ColorPaletteSector,
    ConversionUnitSelector,
  },
  computed:{
    ...ReportsModule.mapGetters(['selectCurrentChart']),
    ...SettingsModule.mapGetters(['selectUserSettings', 'selectApplicationSettings']),

    chart(){
      return this.selectCurrentChart
    },
    settings(){
      return this.selectUserSettings
    },
    displayName() {
      return this.chart ? this.chart.name.replace(/_/gi, ' ').toLowerCase() : null;
    },
    application(){
      return this.selectApplicationSettings
    }
  },
  methods: {
    ...ReportsModule.mapActions(['applyFilters']),

    saveSettings(){
      this.applyFilters({ fields: {...this.form}, chart: this.chart })
    },
    updateDateRange(dateRange){
      this.form.dateRange = dateRange
    },
    updateDefaultPalette(event){
      this.form.palette = event.target.value
    },
    updateConversionUnits(unit){
      this.form.conversionUnit = unit
    }
  }
}
</script>