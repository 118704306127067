<template>
  <div>
    <select
      @input="updateDefaultPalette"
      v-model="palette"
      class="form-select form-control mt-2"
      aria-label="Default palette">
      <option selected value="palette1">Palette1</option>
      <option value="palette2">Palette2</option>
      <option value="palette3">Palette3</option>
      <option value="palette4">Palette4</option>
      <option value="palette5">Palette5</option>
      <option value="palette6">Palette6</option>
      <option value="palette7">Palette7</option>
      <option value="palette8">Palette8</option>
      <option value="palette9">Palette9</option>
      <option value="palette10">Palette10</option>
    </select>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: "PaletteSector",
  components: {},
  data() {
    return {
      palette: null,
    };
  },
  computed: {
    ...SettingsModule.mapGetters(['selectApplicationSettings']),
    
    application(){
      return this.selectApplicationSettings;
    }
  },
  methods: {
    updateDefaultPalette(event) {
      this.$emit("updateDefaultPalette", event);
    },
  },
  mounted() {
    if (this.application) {
      this.palette = this.application.chart.palette;
    }
  },
};
</script>
