<template>
  <div>
    <div :class="classStyle" :style="styling">
      <div class="row">
        <div class="col-6">
          <h6>{{ title }}</h6>
        </div>
        <div class="col-6" style="text-align: right">
          <ion-icon
            @click.prevent="showChartModal"
            data-bs-toggle="modal" 
            data-bs-target="#chartsFiltersModal"  
            :icon="ellipsisHorizontalOutline"
          ></ion-icon>
        </div>
        <ChartsFiltersModal :settings="styling"/>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import { IonIcon } from "@ionic/vue"
import { ellipsisHorizontalOutline } from "ionicons/icons"
import ChartsFiltersModal from '../../modals/ChartFiltersModal.vue'

export default {
  name:'ChartHeaders',
  props: {
    styling:{
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    }, 
    classStyle: {
      type: String,
      required: false,
    }
  },
  components: {
    IonIcon,
    ChartsFiltersModal,
  },
  setup() {
    return {
      ellipsisHorizontalOutline
    }
  },
  methods: {
    showChartModal(){
      this.$emit('ChartFiltersModelShow', {})
      $('#chartsFiltersModal').prependTo('body')
    }
  }
}
</script>